@import url("https://fonts.googleapis.com/css2?family=Rampart+One&display=swap");

.App {
  min-height: 100vh;
  display: flex;
  background-image: url("./background.jpg");
  background-size: cover;
  background-position: center;
}

::-webkit-scrollbar {
  width: 0;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(136, 136, 136, 0.2);
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}
